import StoryblokClient from "storyblok-js-client";

const Storyblok = new StoryblokClient({
  accessToken: "5JtZUk5QWzkF0Cs2CAyC6wtt",
});

let carouselIntervalId = null;

function getPageHash() {
  return location.hash ? location.hash : "#index";
}

function activateNavitem() {
  const navItems = document.querySelectorAll(".nav__item");
  navItems.forEach((item) => item.classList.remove("active"));

  const pageHash = getPageHash();
  const activeNavItems = document.querySelectorAll(`[data-link="${pageHash}"]`);
  if (activeNavItems.length) {
    activeNavItems.forEach((item) => item.classList.add("active"));
  }
}

function addNavLinks() {
  const navItems = document.querySelectorAll(".nav__item");

  navItems.forEach((item) => {
    item.addEventListener("click", function (event) {
      const linkPage = event.target.getAttribute("data-link");
      document.location.href = `${linkPage}`;
    });
  });
}

function setTitle() {
  const activeNavItem = document.querySelector(".nav__item.active");
  const title = activeNavItem?.textContent;

  if (title && title !== "Home") {
    const titleDiv = document.querySelector("#pageTitle");
    const clone = titleDiv.content.cloneNode(true);
    const headerDiv = clone.querySelector("h3");
    headerDiv.innerHTML = title;

    document.querySelector("#content").appendChild(clone);
  }
}

async function getImageList(topic) {
  try {
    const response = await Storyblok.get(`cdn/stories/${topic}`);
    return response.data.story.content.images;
  } catch (error) {
    console.log(error);
  }
}

function closeModal() {
  const modalDiv = document.querySelector("#modalDiv");
  if (modalDiv) {
    modalDiv.remove();
  }
}

function showModal() {
  if ("content" in document.createElement("template")) {
    const modalDiv = document.querySelector("#modal");
    const clone = modalDiv.content.cloneNode(true);

    const imageDiv = clone.querySelector("img");
    imageDiv.src = `${this.image.filename}${this.modalSrcSuffix}`;

    document.body.appendChild(clone);
    const insertedModalDiv = document.querySelector("#modalDiv");
    const insertedImage = insertedModalDiv.querySelector("figure");
    insertedImage.classList.add("translate-y-full");

    requestAnimationFrame(() => {
      insertedImage.classList.remove("translate-y-full");
    });

    const closeModalButton = document.querySelector("#modalClose");
    closeModalButton.addEventListener("click", closeModal);
  }
}

function addClickOutsideModal() {
  document.addEventListener("click", (event) => {
    const modalDiv = document.querySelector("#modalDiv");
    const modalWrapper = document.querySelector(".modal__wrapper");
    if (modalDiv) {
      const isClickInsideModal = modalWrapper.contains(event.target);
      if (!isClickInsideModal) {
        closeModal();
      }
    }
  });
}

function loadImageListContent(imageList) {
  if ("content" in document.createElement("template")) {
    const contentDiv = document.querySelector("#content");
    const imageListDiv = document.createElement("ul");
    const imageRowDiv = document.querySelector("#imageRow");

    contentDiv.appendChild(imageListDiv);

    imageList.forEach((imageItem) => {
      if (imageItem.image.filename) {
        const imagePreload = new Image();
        const clone = imageRowDiv.content.cloneNode(true);

        const imageDiv = clone.querySelector("img");

        let imageSrcSuffix = "";

        switch (imageItem.format) {
          case "landscape":
            imageSrcSuffix = "/m/700x0";
            break;
          case "portrait":
            imageSrcSuffix = "/m/500x0";
            break;
          case "square":
            imageSrcSuffix = "/m/600x0";
            break;
        }

        imageItem.modalSrcSuffix = "/m/0x800";

        imageDiv.src = `${imageItem.image.filename}${imageSrcSuffix}`;

        imagePreload.src = `${imageItem.image.filename}${imageItem.modalSrcSuffix}`;

        const figcaptionDiv = clone.querySelector("figcaption");
        figcaptionDiv.textContent = imageItem.description;

        imageListDiv.appendChild(clone);
        const insertedRow = imageListDiv.querySelector("li:last-child");
        insertedRow.classList.add("opacity-0");

        requestAnimationFrame(() => {
          insertedRow.classList.remove("opacity-0");
        });

        imageDiv.addEventListener("click", (event) => {
          event.stopPropagation();
          showModal.call(imageItem);
        });
      }
    });
  }
}

async function loadTopicPage(topic) {
  const imageListTopic = await getImageList(topic);
  loadImageListContent(imageListTopic);
}

async function getStoryblokStory(name) {
  try {
    const response = await Storyblok.get(`cdn/stories/${name}`);
    const storyblokContent = response.data.story.content.textContent;
    return Storyblok.richTextResolver.render(storyblokContent);
  } catch (error) {
    console.log(error);
  }
}

async function loadDynPage(name) {
  const dynContent = await getStoryblokStory(name);
  const div = document.createElement("div");
  div.innerHTML = dynContent;
  div.classList.add("opacity-0", "content__text");
  document.querySelector("#content").appendChild(div);

  requestAnimationFrame(() => {
    div.classList.remove("opacity-0");
  });
}

async function loadHome() {
  const imageListHome = await getImageList("home");
  const imageList = imageListHome.map((imgComp) => {
    const { image, format } = imgComp;
    const { filename, name } = image;
    return {
      filename: `${filename}/m/1000x0`,
      name,
      format,
    };
  });

  const contentDiv = document.querySelector("#content");
  const carouselDiv = document.querySelector("#imageCarousel");
  const clone = carouselDiv.content.cloneNode(true);

  const imgContainer = clone.querySelector(".carousel__container");
  const firstImg = imgContainer.querySelector(".carousel__img");
  firstImg.src = imageList[0].filename;
  firstImg.alt = imageList[0].name;

  contentDiv.appendChild(clone);
  const carouselWrapper = document.querySelector(".carousel__wrapper");

  let currentIndex = 1;

  function updateImage() {
    const currentImgContainer = document.querySelector(".carousel__container");
    const currentImg = currentImgContainer.querySelector(".carousel__img");

    const imgContainerClone = currentImgContainer.cloneNode(true);
    const newImg = imgContainerClone.querySelector(".carousel__img");
    newImg.classList.add("opacity-0");
    newImg.classList.add("translate-x-[2500px]");

    newImg.src = imageList[currentIndex].filename;
    newImg.alt = imageList[currentIndex].name;
    carouselWrapper.appendChild(imgContainerClone);

    newImg.addEventListener(
      "load",
      function () {
        currentImg.classList.add("opacity-0");
        currentImg.classList.add("translate-x-[2500px]");

        setTimeout(() => {
          newImg.classList.remove("opacity-0");
          newImg.classList.remove("translate-x-[2500px]");
        }, 500);

        setTimeout(() => {
          currentImgContainer.remove();
        }, 1500);
      },
      false
    );

    currentIndex++;
    if (currentIndex === imageList.length) {
      currentIndex = 0;
    }
  }

  function play() {
    carouselIntervalId = setInterval(() => {
      updateImage();
    }, 5000);
  }

  play();
}

async function loadContent() {
  const contentDiv = document.querySelector("#content");
  contentDiv.innerHTML = "";
  setTitle();
  const pageHash = getPageHash();

  switch (pageHash) {
    case "#index":
      loadHome();
      break;
    case "#skulptur":
      loadTopicPage("skulptur");
      break;
    case "#malerei":
      loadTopicPage("malerei");
      break;
    case "#vita":
      loadDynPage("vita");
      break;
    case "#kontakt":
      loadDynPage("kontakt");
      break;
    case "#impressum":
      loadDynPage("impressum");
      break;
  }
}

function toggleMobileMenu() {
  document.getElementById("mobile-menu").classList.toggle("hidden");
  document.getElementById("menu").classList.toggle("hidden");
  document.getElementById("toggle-menu").classList.toggle("hidden");
  document.getElementById("toggle-close").classList.toggle("hidden");
}

function loadPage() {
  activateNavitem();
  addNavLinks();
  if (!document.getElementById("mobile-menu").classList.contains("hidden")) {
    toggleMobileMenu();
  }
  loadContent();
}

document.addEventListener("DOMContentLoaded", function () {
  addClickOutsideModal();
  loadPage();

  document
    .getElementById("toggle-menu-button")
    .addEventListener("click", function () {
      toggleMobileMenu();
    });
});

window.addEventListener(
  "hashchange",
  () => {
    closeModal();
    clearInterval(carouselIntervalId);
    loadPage();
  },
  false
);
